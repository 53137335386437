/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  console.log('initial render')
  window.localStorage.setItem('hasSignedIn', 0)

  let vh = window.innerHeight * 0.01

  window.addEventListener('resize', () => {
    // あとは上記と同じスクリプトを実行
    // let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })

  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
