module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hajimex Murakami Website","short_name":"hajimex web","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/images/overlay.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c2d2f4d92c31072bb430e0ed0a609294"},
    },{
      plugin: require('../node_modules/gatsby-source-vimeo-all-download/gatsby-browser.js'),
      options: {"plugins":[],"clientId":"2ebad49d7cd9dff71b03689b0cbe5b2491a2fd57","clientSecret":"ITQkPPF1GJa7Olq9P6WJSls0yFR5MdqytG+hOTRjxzZvr/ChvgIS8/shQSCNtNUzfvU0B8Lh03Hf473nFfRfeSqXzAYxS6qe0aojaSDZuJVjc/hLRpG9O8we460a4TB/","accessToken":"c362f2aaa5d34b7d909dd73fb2cf0cf3","portfolioId":"781469"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
